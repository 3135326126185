import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const ProjectCard = ({ project }) => {
  return (
    <div className="sm:max-w-md overflow-hidden border-b border-gray-600">
      <Link to={project.fields.slug}>
        <ProjectImage image={project.frontmatter.image} />
        <div className="px-6 py-2">
          <div className="font-bold text-xl mb-2">
            {project.frontmatter.name}
          </div>
          <p className="text-gray-700 text-base">
            {project.frontmatter.description}
          </p>
        </div>
      </Link>
    </div>
  )
}

const ProjectImage = ({ image }) => {
  const imageStyle = {
    width: "100%",
    height: 200,
    overflow: "hidden",
  }
  return <Img style={imageStyle} fluid={image.childImageSharp.fluid} />
}

const ProjectList = ({ data }) => {
  const { edges: projects } = data.allMarkdownRemark
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
      {projects &&
        projects.map(({ node: project }, i) => (
          <ProjectCard key={project.id} project={project} />
        ))}
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "project-page" } } }
        ) {
          edges {
            node {
              id
              html
              fields {
                slug
              }
              frontmatter {
                name
                description
                image {
                  id
                  childImageSharp {
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ProjectList data={data} />}
  ></StaticQuery>
)
