import React from "react"

import Layout from "../../components/layout"
import ProjectList from "../../components/ProjectList"

const ProjectsPage = () => {
  return (
    <Layout>
      <ProjectList />
    </Layout>
  )
}

export default ProjectsPage
